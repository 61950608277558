import { Link } from "react-router-dom";
import { useTheme } from "../hooks/useTheme";
import deleteIcon from "../assets/delete-icon.svg";
import { projectFirestore } from "../firebase/config";

// styles
import "./RecipeList.css";

const RecipeList = ({ recipes }) => {
    const { mode } = useTheme();

    const handleDeleteClick = (id) => {
        projectFirestore.collection("recipes").doc(id).delete();
    };

    if (recipes.length === 0) {
        return <p className="message">No recipes to load</p>;
    }
    return (
        <div className="recipe-list">
            {recipes.map((recipe) => (
                <div key={recipe.id} className={`card ${mode}`}>
                    <h3>{recipe.title}</h3>
                    <p>{recipe.cookingTime}</p>
                    <div>{recipe.method.substring(0, 100)}...</div>
                    <Link to={`/recipes/${recipe.id}`}>Cook This</Link>
                    <img
                        src={deleteIcon}
                        className="delete"
                        alt="deletetrash"
                        onClick={() => handleDeleteClick(recipe.id)}
                    ></img>
                </div>
            ))}
        </div>
    );
};

export default RecipeList;
