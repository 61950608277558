import { useTheme } from "../hooks/useTheme";

// style
import "./ThemeSelector.css";

import modeIcon from "../assets/mode-icon.svg";

const themeColors = ["#06b6d4", "#14b8a6", "#8b5cf6", "#f43f5e"];

const ThemeSelector = () => {
    const { mode, changeColor, changeMode } = useTheme();

    const toggleMode = () => {
        changeMode(mode === "dark" ? "light" : "dark");
    };

    return (
        <div className="theme-selector">
            <div className="mode-toggle">
                <img
                    src={modeIcon}
                    onClick={toggleMode}
                    alt="dark/light toggle icon"
                    style={{
                        filter:
                            mode === "dark" ? "invert(100%)" : "invert(20%)",
                    }}
                ></img>
            </div>
            <div className="theme-buttons">
                {themeColors.map((color) => (
                    <div
                        key={color}
                        onClick={() => changeColor(color)}
                        style={{ background: color }}
                    />
                ))}
            </div>
        </div>
    );
};

export default ThemeSelector;
