import { useEffect, useState } from "react";
import { useTheme } from "../../hooks/useTheme";

import { projectFirestore } from "../../firebase/config";

// styles
import "./Home.css";

// components
import RecipeList from "../../components/RecipeList";

const Home = () => {
    const { mode } = useTheme();

    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsPending(true);

        // onSnapshot - event listener, live updates on the recipe collection
        const unsub = projectFirestore.collection("recipes").onSnapshot(
            (snapshot) => {
                if (snapshot.empty) {
                    setError("No recipes to load");
                    setIsPending(false);
                } else {
                    let results = [];
                    snapshot.docs.forEach((doc) => {
                        results.push({ id: doc.id, ...doc.data() });
                    });

                    setData(results);
                    setIsPending(false);
                }
            },
            (err) => {
                setError(err.message);
                setIsPending(false);
            }
        );

        // unsubscribe from event listener
        return () => unsub();
    }, []);

    return (
        <div className={`home ${mode}`}>
            {error && <p className="error">{error}</p>}
            {isPending && <p className="loading">Loading...</p>}
            {data && <RecipeList recipes={data} />}
        </div>
    );
};

export default Home;
