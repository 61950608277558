import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { projectFirestore } from "../../firebase/config";
import { useTheme } from "../../hooks/useTheme";

// styles
import "./Create.css";

const Create = () => {
    const [title, setTitle] = useState("");
    const [method, setMethod] = useState("");
    const [cookingTime, setCookingTime] = useState("");
    const [newIngredient, setNewIngredient] = useState("");
    const [ingredients, setIngredients] = useState([]);
    const ingredientsInput = useRef(null);
    const { color, mode } = useTheme();

    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const doc = {
            title,
            method,
            cookingTime: `${cookingTime} minutes`,
            ingredients,
        };
        try {
            await projectFirestore.collection("recipes").add(doc);
            // redirect when saved
            history.push("/");
        } catch (err) {
            console.log(err);
        }
    };

    const handleAdd = (e) => {
        e.preventDefault();
        const ing = newIngredient.trim();

        if (ing && !ingredients.includes(ing)) {
            setIngredients((prevIng) => [...prevIng, ing]);
            setNewIngredient("");
            ingredientsInput.current.focus();
        }
    };

    return (
        <div className={`create ${mode}`}>
            <h2 className="page-title">Add a New Recipe</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    <span>Recipe title:</span>
                    <input
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        required
                    ></input>
                </label>
                <label>
                    <span>Recipe ingredients:</span>
                    <div className="ingredients">
                        <input
                            type="text"
                            onChange={(e) => setNewIngredient(e.target.value)}
                            value={newIngredient}
                            ref={ingredientsInput}
                        ></input>
                        <button
                            className="btn"
                            style={{ background: color }}
                            onClick={handleAdd}
                        >
                            add
                        </button>
                    </div>
                </label>
                <p>
                    Current ingredients:{" "}
                    {ingredients.map((i) => (
                        <em key={i}>{i},</em>
                    ))}
                </p>
                <label>
                    <span>Recipe method:</span>
                    <textarea
                        onChange={(e) => setMethod(e.target.value)}
                        value={method}
                        required
                    />
                </label>
                <label>
                    <span>Cooking time (minutes):</span>
                    <input
                        type="number"
                        onChange={(e) => setCookingTime(e.target.value)}
                        value={cookingTime}
                        required
                    ></input>
                </label>

                <button className="btn" style={{ background: color }}>
                    Submit
                </button>
            </form>
        </div>
    );
};

export default Create;
