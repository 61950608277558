import { createContext, useReducer } from "react";

// use this with useContext hook on other components to access data.
export const ThemeContext = createContext();

// this is a pure JS function - does not live inside the component.
// state - current state before updates
// action - object with type and payload as properties.used to determine what to change.
const themeReducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_COLOR":
            return { ...state, color: action.payload };
        case "CHANGE_MODE":
            return { ...state, mode: action.payload };
        default:
            return state;
    }
};

export const ThemeProvider = ({ children }) => {
    // dispatch sends a state change to the reducer
    const [state, dispatch] = useReducer(themeReducer, {
        color: "#06b6d4",
        mode: "dark",
    });

    const changeColor = (color) => {
        dispatch({ type: "CHANGE_COLOR", payload: color });
    };

    const changeMode = (mode) => {
        dispatch({ type: "CHANGE_MODE", payload: mode });
    };

    return (
        <ThemeContext.Provider value={{ ...state, changeColor, changeMode }}>
            {children}
        </ThemeContext.Provider>
    );
};

//#58249c
