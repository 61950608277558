import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDJ5EJRenx3pn6J7i__Vi0m4z_1H6iVezc",
    authDomain: "ninja-cooking-tracker.firebaseapp.com",
    projectId: "ninja-cooking-tracker",
    storageBucket: "ninja-cooking-tracker.appspot.com",
    messagingSenderId: "956128769595",
    appId: "1:956128769595:web:92984196c892d16f0a8e1e",
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore(); // this is possible from firebase/firestore import

// to use in other parts of the app
export { projectFirestore };
