import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { projectFirestore } from "../../firebase/config";
import { useTheme } from "../../hooks/useTheme";

//styles
import "./Recipe.css";

const Recipe = () => {
    const { id } = useParams();
    const { mode } = useTheme();

    const [recipe, setRecipe] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsPending(true);
        const unsub = projectFirestore
            .collection("recipes")
            .doc(id)
            .onSnapshot((doc) => {
                if (doc.exists) {
                    setIsPending(false);
                    setRecipe(doc.data());
                } else {
                    setIsPending(false);
                    setError("Could not find the recipe");
                }
            });
        // unsubscribe from event listener.
        return () => unsub();
    }, [id]);

    const handleUpdateClick = () => {
        // firebase will only update specified props. no need to pass other props
        projectFirestore
            .collection("recipes")
            .doc(id)
            .update({ title: "Newly set title!" });
    };

    return (
        <div className={`recipe ${mode}`}>
            {isPending && <p className="loading">Loading...</p>}
            {error && <p className="error">{error}</p>}
            {recipe && (
                <>
                    <h2 className="page-title">{recipe.title}</h2>
                    <p>Takes {recipe.cookingTime}</p>
                    <ul>
                        {recipe.ingredients.map((ing) => (
                            <li key={ing}>{ing}</li>
                        ))}
                    </ul>
                    <p className="method">{recipe.method}</p>
                    {
                        //! taking out for production deployment preventing users from updating the title!
                        /* 
                    <button onClick={handleUpdateClick}>Update me</button> 
                    */
                    }
                </>
            )}
        </div>
    );
};

export default Recipe;
